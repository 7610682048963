// please add newest flags at the top of the list

export const flagIds = [
  "trial-reminders-toggle",
  "simple-messaging",
  "payment-location-mode",
  "hcp-details",
  "diagnosis-question-lp",
  "trial-cancelation-flow",
  "magic-deep-link",
  "tailored-value-prop",
  "personal-story",
  "user-insight-popup",
  "paywall-before-results",
  "dedupe-account-funnel-changes",
  "pricing-page-tests",
  "apple-pay-modal",
  "upfront-payments",
  "share-results",
  "localized-currency",
  "paypal-payments",
  "program-pricing",
  "show-quiz-error",
  "illustration-question-lp",
  "emoji-question-lp",
  "growthbook-aa-test",
  "growthbook-aa-test-continue",
  "loading-program",
] as const;

// DevMenu only
// if a flag is NOT in this list, variant will display as a text input
export const flagVariants: Record<string, string[]> = {
  "loading-program": ["control", "loading-program"],
  "trial-reminders-toggle": ["control", "trial-reminders-toggle"],
  "simple-messaging": ["control", "simple-messaging"],
  "payment-location-mode": ["default", "user", "auto"],
  "hcp-details": ["control", "hcp-details"],
  "diagnosis-question-lp": [
    "control",
    "diagnosis-question-lp",
    "diagnosis-question-review-lp",
    "diagnosis-question-trust-lp",
  ],
  "trial-cancelation-flow": ["control", "trial-cancelation-flow"],
  "tailored-value-prop": ["control", "tailored-value-prop"],
  "user-insight-popup": [
    "control",
    "price-options",
    "result-adhd-calculations",
  ],
  "paywall-before-results": ["control", "paywall-before-results"],
  "pricing-page-tests": [
    "control",
    "trial-focus",
    "benefits-focus",
    "design-refresh",
  ],
  "apple-pay-modal": [],
  "upfront-payments": ["control", "upfront-v3-2options", "upfront-v3-3options"],
  "share-results": [],
  "localized-currency": ["control", "localized"],
  "paypal-payments": [],
  "program-pricing": [],
  "show-quiz-error": [],
  "illustration-question-lp": [
    "control",
    "productive-question-lp",
    "​priority-question-lp",
  ],
  "emoji-question-lp": ["control", "feeling-question-lp", "putoff-question-lp"],
  "personal-story": ["control", "personal-story"],
  "growthbook-aa-test": ["control", "growthbook-aa-test"],
  "growthbook-aa-test-continue": ["control", "growthbook-aa-test-continue"],
  "magic-deep-link": ["control", "magic-deep-link"],
} as const;

export type FlagId = (typeof flagIds)[number];
